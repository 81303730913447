import React from 'react';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';

import MapsMarker from './MapsMarker';

export default function Maps(props) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyBT8b3-Uz0dP_qSkFU8w4kH5_ld8NAhAuA',
  });

  if (!isLoaded) return <div>Loading...</div>;

  const locations = props.events.map((e) => e.location);
  const markers = Object.values(
    locations.reduce(
      (acc, obj) => ({
        ...acc,
        [obj.id]: {
          ...obj,
          latitude: parseFloat(obj.latitude),
          longitude: parseFloat(obj.longitude),
          events: props.events.filter((e) => e.location.id === obj.id),
        },
      }),
      {}
    )
  );

  return (
    <GoogleMap zoom={8} center={{ lat: 52.2434979, lng: 5.6343227 }} mapContainerClassName="map">
      {markers.map((m) => (
        <MapsMarker key={m.id} marker={m} />
      ))}
    </GoogleMap>
  );
}
